<template>
  <div>
    <div class="e-breadcrumb">
      <span style="margin-left: -10px" @click="closeEvent">服务项目</span>
      > 新增
    </div>
    <div class="order-table-wrapper">
      <div class="e-card">
    <el-form ref="form" label-width="180px" style="margin-top: 30px">
      <el-form-item size="small" label="项目编号:" required>
        <el-input v-model="itemNo" placeholder="请输入项目编号" maxlength="32" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="项目名称:" required>
        <el-input v-model="itemName" placeholder="请输入项目名称" maxlength="64" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="分类:" required>
        <el-select size="small" style="width: 405px" v-model="categoryId" placeholder="请选择">
          <el-option
            v-for="item in categoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item size="small" label="排序:" required>
        <el-input v-model="sort" placeholder="请输入排序" maxlength="32" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="类别:" required>
        <el-radio-group v-model="itemType">
          <el-radio :label="0">小项</el-radio>
          <el-radio :label="1">大项</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="small" label="回访:" required>
        <el-radio-group v-model="returnVisit">
          <el-radio :label="0">不发送</el-radio>
          <el-radio :label="1">发送</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="small" label="提醒:" required>
        <el-radio-group v-model="remind">
          <el-radio :label="0">不发送</el-radio>
          <el-radio :label="1">发送</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="small" label="加入套餐:" required>
        <el-radio-group v-model="setMeal">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="small" label="是否自动入单:" required>
        <el-radio-group v-model="isAutoEntry">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <!--<el-form-item size="small" label="适合门店:" required>-->
        <!--<div style="width: 600px">-->
          <!--<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>-->
          <!--<div style="margin: 15px 0;"></div>-->
          <!--<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">-->
            <!--<el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox>-->
          <!--</el-checkbox-group>-->
        <!--</div>-->
      <!--</el-form-item>-->
      <el-form-item size="small" label="项目图片:" required>
        <div class="graphic-description-value">
          <div>
            <el-upload
              class="e-file-upload"
              :action="Base_url+'/admin/uploadImg'"
              :on-success="imageSuccess1"
              :before-upload="beforeUpload"
              :show-file-list="false"
            >
              <el-image v-if="itemImgUrl"
                        style="width: 137px; height: 137px"
                        :src="itemImgUrl"
                        fit="contain"></el-image>
              <div class="e-upload--text" v-if="!itemImgUrl">
                <span class="e-upload--icon el-icon-plus " style="margin-top: 30px"></span>
                <p>添加上传图片</p>
              </div>
              <p class="e-file-upload-delete" v-if="itemImgUrl"
                 @click.stop="itemImgUrl=''">
                <i class="el-icon-delete"></i>
              </p>
            </el-upload>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="card-footer" style="width: 580px;margin-bottom: 30px">
      <el-button size="small" @click="closeEvent">取 消</el-button>
      <el-button size="small" type="primary" @click="saveCategory">确 定</el-button>
    </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Base_url} from '../../config/index'
  import {dataFormat} from '../../utils/main'
  export default {
    data() {
      return {
        Base_url: '',
        itemNo: '',
        itemName: '',
        categoryId: '',
        itemType: 0,
        returnVisit: 0,
        remind: 0,
        setMeal:0,
        isAutoEntry:0,
        itemImgUrl: '',
        sort: '',

        checkTotal: 0,

        categoryList: [],

      }
    },
    props: {
      addServiceItem: Boolean
    },
    created() {
      this.Base_url = Base_url
      this.getCategoryList()
    },
    methods: {

     async getCategoryList(){
       let data ={
         categoryType:0
       }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code==200){
          this.categoryList =res.categoryList
        }
      },

      //图片相关
      imageSuccess1(res) {
        this.itemImgUrl = res.fileUrl
      },
      // 上传文件之前
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 3;

        if (!isJPG && !isPNG) {
          this.$message.error('请上传JPG或PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传大小不能超过 3MB!');
        }
        return (isJPG || isPNG) && isLt2M;
      },

      closeEvent() {
        this.$router.push('/baseData/serviceItem?index=0')
      },

      async saveCategory() {
        let data = {
          itemNo: this.itemNo,
          itemName: this.itemName,
          categoryId: this.categoryId,
          itemType: this.itemType,
          returnVisit: this.returnVisit,
          remind: this.remind,
          setMeal: this.setMeal,
          sort: this.sort,
          isAutoEntry: this.isAutoEntry,
          itemImgUrl: this.itemImgUrl,
        };

        if (!this.itemNo) {
          this.$message.error("请填写项目编号");
          return
        }
        if (!this.itemName) {
          this.$message.error("请填写项目名称");
          return
        }

        if (!this.categoryId) {
          this.$message.error("请选择分类");
          return
        }
        if (!this.itemImgUrl) {
          this.$message.error("请上传图片");
          return
        }

        let res = await this.$post("/admin/save_bsServiceItem", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push('/baseData/serviceItem?index=0')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>

<style>

</style>
